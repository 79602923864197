import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAppState } from '../../state';

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isAuthReady } = useAppState();

  if (!isAuthReady) {
    return null;
  }

  return <Route {...rest} render={({ location }) => children} />;
}
