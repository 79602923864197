import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useAppState } from '../../../state';
import UserAvatar from './UserAvatar/UserAvatar';
import * as Cookies from 'js-cookie';

const useStyles = makeStyles({
  userContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  userButton: {
    color: 'white',
  },
});

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const { user } = useAppState();

  if (Cookies.get(process.env.REACT_APP_EXPERT_AUTH_COOKIE_NAME as string)) {
    return (
      <div className={classes.userContainer}>
        <UserAvatar user={user} />
      </div>
    );
  }

  return null;
};

export default UserMenu;
