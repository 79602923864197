import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useIndexedDB from './useIndexedDB/useIndexedDB';
import useExpertAuth from './useExpertAuth/useExpertAuth';
import useLanguage from './useLanguage/useLanguage';
import * as Cookies from 'js-cookie';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  isScheduled: boolean;
  getToken(name: string, slug: string, room: string, passcode?: string): Promise<string>;
  getTestTokens(publisher: string, subscriber: string, room: string): Promise<[string, string] | undefined>;
  user?: null | { id: number | undefined; displayName: string; isExpert: boolean; photoURL: string | undefined };
  signIn?(passcode?: string): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  lang: string;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    ...useLanguage(),
  } as StateContextType;

  if (Cookies.get(process.env.REACT_APP_EXPERT_AUTH_COOKIE_NAME as string)) {
    contextValue = {
      ...contextValue,
      ...useExpertAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      ...useIndexedDB(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  }

  const getToken: StateContextType['getToken'] = (name, slug, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, slug, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const getTestTokens: StateContextType['getTestTokens'] = (publisher, subscriber, room) => {
    setIsFetching(true);
    return contextValue
      .getTestTokens(publisher, subscriber, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, getTestTokens }}>{props.children}</StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
